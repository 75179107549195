import useSWR from 'swr';

import {
  CreateCustomFieldsDto,
  CreateTokenListDto,
  CreateTokenListItemDto,
  TokenListDto,
  UpdateTokenListCustomFieldsDto,
  UpdateTokenListDto,
  UpdateTokenListItemDto,
} from '../api/api';
import {
  createCustomFields,
  createToken,
  createTokenList,
  getPartnersList,
  updateToken as updateTokenApi,
  updateTokenList,
  updateTokenListCustomFields,
} from '../utils/api';
import { useUserInfo } from './useUserInfo';

export const usePartnersList = (listId?: string) => {
  const { userInfo, isLoading: isUserInfoLoading, error: userInfoError, mutate: mutateUserInfo } = useUserInfo();

  const projectId = String(userInfo?.projects[0]?.id);

  const fetchPartnersList = async (): Promise<TokenListDto | undefined> => {
    if (listId) {
      const response = await getPartnersList(listId.toString());
      if (response) return response.data;
    }
  };

  const { data, error, mutate } = useSWR<TokenListDto | undefined>(
    listId && !isUserInfoLoading && !userInfoError ? `partnersList-${listId}` : null,
    listId ? fetchPartnersList : null
  );

  const createList = async (listData: CreateTokenListDto) => {
    if (projectId && listData) {
      await createTokenList(listData, projectId);
      await mutateUserInfo();
      mutate();
    }
  };

  const updateList = async (updateData: UpdateTokenListDto) => {
    if (listId) {
      await updateTokenList(updateData, listId);
      mutate();
    }
  };

  const updateCustomFields = async (updateData: UpdateTokenListCustomFieldsDto) => {
    if (listId) {
      await updateTokenListCustomFields(updateData, listId);
      mutate();
    }
  };

  const createNewCustomFields = async (createData: CreateCustomFieldsDto, newListId?: string) => {
    const actualListId = listId || newListId;
    if (actualListId) {
      await createCustomFields(createData, actualListId);
      mutate();
    }
  };

  const addToken = async (tokenData: CreateTokenListItemDto) => {
    if (listId) {
      await createToken(tokenData);
      mutate();
    }
  };

  const updateToken = async (tokenData: UpdateTokenListItemDto, tokenId: string) => {
    if (tokenData) {
      await updateTokenApi(tokenData, tokenId);
      mutate();
    }
  };

  const defaultPartnerList: TokenListDto = {
    id: 0,
    name: '',
    items: [],
    customFieldsLabels: [],
  };

  const sortedPartnerList = data
    ? { ...data, items: data.items.sort((a, b) => Number(b.isActive) - Number(a.isActive)) }
    : defaultPartnerList;

  return {
    partnerList: sortedPartnerList,
    isLoading: isUserInfoLoading || (!error && !data),
    error: userInfoError || error,
    createList,
    updateList,
    updateCustomFields,
    createNewCustomFields,
    addToken,
    updateToken,
  };
};
