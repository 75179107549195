import { ArcElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import { AbstractStatisticsData } from '../types/analyticsType';
import { stringToColor } from '../utils/helpers';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

interface AnalyticsPieChartProps {
  analyticsData: AbstractStatisticsData;
  selectedAction: string;
}

const AnalyticsPieChart: React.FC<AnalyticsPieChartProps> = ({ analyticsData, selectedAction }) => {
  const labels = analyticsData.stats.map((token) => token.pointer);
  const colors = labels.map((label, index) => stringToColor(label + index));

  let dataValues: number[] = [];

  if (selectedAction === 'Total') {
    dataValues = analyticsData.stats.map((token) => token.totalActionsCount);
  } else {
    const actionId = analyticsData.labels.find((label) => label.title === selectedAction)?.id;
    dataValues = analyticsData.stats.map((token) => token.actions[actionId!]?.value || 0);
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'Action Statistics',
        data: dataValues,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return <Pie data={data} options={options} />;
};

export default AnalyticsPieChart;
