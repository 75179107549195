export enum Endpoints {
  UserInfo = '/dashboard/user',
  PartnersList = '/dashboard/list/{listId}',
  CreateToken = '/dashboard/token',
  UpdateToken = '/dashboard/token/{tokenId}',
  GenerateAndGetApiKey = '/dashboard/project/{projectId}/api-key',
  GetNetworksList = '/networks',
  UsersAnalytics = '/dashboard/analytics/{projectId}/users',
  TokensAnalytics = '/dashboard/analytics/{projectId}/tokens',
}
