import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import useSWR from 'swr';

import { UserInfoDto } from '../api/api';
import { Endpoints } from '../constants/endpoints';
import { BASE_PATH } from '../constants/routes';
import { DecodedJWT } from '../types/jwtType';
import { getUserInfo } from '../utils/api';
import { useAuthActions } from './useAuthActions';

export const useUserInfo = () => {
  const token = localStorage.getItem('jwt') || '';

  const isAuthenticated = () => !!token;

  const { logout } = useAuthActions();

  const emptyDecodedJWT: DecodedJWT = {
    name: '',
    email: '',
    role: '',
    iat: 0,
    exp: 0,
  };

  const decodedJwt = token ? jwtDecode<DecodedJWT>(token) : emptyDecodedJWT;

  const isTokenExpired = () => {
    if (!decodedJwt || !decodedJwt.exp) return true;
    return decodedJwt.exp < Math.floor(Date.now() / 1000);
  };

  useEffect(() => {
    if (isTokenExpired() && decodedJwt.exp !== 0) {
      logout();
    }
  }, [decodedJwt.exp, logout]);

  const fetchUserInfo = async (): Promise<UserInfoDto | undefined> => {
    const response = await getUserInfo();
    if (response) return response.data;
  };

  const { data, error, mutate } = useSWR<UserInfoDto | undefined>(Endpoints.UserInfo, fetchUserInfo);

  const firstProjectLink = BASE_PATH.PROJECT.replace(':projectId', String(data?.projects[0].id));

  return {
    isAuthenticated,
    userInfo: data,
    firstProjectLink,
    decodedJwt,
    isLoading: !error && !data,
    error,
    mutate,
  };
};
