import { useState } from 'react';
import useSWR from 'swr';

import { ApiKeyDataDto, UpdateProjectDto } from '../api/api';
import { Endpoints } from '../constants/endpoints';
import { generateNewApiKey, getApiKeyByProjectId, updateProject } from '../utils/api';
import { useUserInfo } from './useUserInfo';

interface UseProjectInfoReturn {
  isLoading: boolean;
  isGenerating: boolean;
  isUpdating: boolean;
  error: Error | null;
  generateApiKey: () => Promise<void>;
  updateProjectInfo: (updateData: UpdateProjectDto) => Promise<void>;
  data: ApiKeyDataDto | undefined;
}

export const useProjectInfo = (projectId: string): UseProjectInfoReturn => {
  const { isLoading: isUserInfoLoading, error: userInfoError } = useUserInfo();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { data, error, mutate } = useSWR<ApiKeyDataDto | undefined>(
    projectId ? Endpoints.GenerateAndGetApiKey.replace('{projectId}', projectId) : null,
    async () => {
      const result = await getApiKeyByProjectId(projectId);
      if (result) return result.data;
    }
  );

  const generateApiKey = async () => {
    setIsGenerating(true);
    try {
      await generateNewApiKey(projectId);
      const result = await getApiKeyByProjectId(projectId);
      if (result && result.data) mutate(result.data, false);
    } catch (err) {
      console.error('Error generating new API key:', err);
    } finally {
      setIsGenerating(false);
    }
  };

  const updateProjectInfo = async (updateData: UpdateProjectDto) => {
    setIsUpdating(true);
    try {
      await updateProject(projectId, updateData);
      mutate();
    } catch (err) {
      console.error('Error updating project:', err);
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    isLoading: isUserInfoLoading || (!error && !data),
    isGenerating,
    isUpdating,
    error: userInfoError || error,
    generateApiKey,
    updateProjectInfo,
    data,
  };
};
