import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import React, { useState } from 'react';

import { AnalyticsTabs } from '../types/analityticsTabs';
import { AbstractStatisticsData } from '../types/analyticsType';
import { FileHeader } from '../types/exportType';
import { OrderType } from '../types/sortingType';
import { getFilename, stringToColor } from '../utils/helpers';
import ExportButton from './ExportButton';

interface AnalyticsTableProps {
  type: AnalyticsTabs;
  isLoading: boolean;
  analyticsData: AbstractStatisticsData | undefined;
}

interface Data {
  name: string;
  totalActionsCount: number;
  [key: string]: any;
  color: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator<Key extends keyof Data>(order: OrderType, orderBy: Key): (a: Data, b: Data) => number {
  return order === OrderType.DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const AnalyticsTable: React.FC<AnalyticsTableProps> = ({ type, analyticsData, isLoading }) => {
  const [order, setOrder] = useState<OrderType>(OrderType.ASC);
  const [orderBy, setOrderBy] = useState<string>('name');

  const isPartnerType = type === AnalyticsTabs.Partners;

  const columnName = isPartnerType ? 'Partner' : 'User';

  const labels = analyticsData?.labels || [];
  const tokens = analyticsData?.stats || [];

  const rows: Data[] = tokens.map((token, index) => ({
    name: token.pointer,
    totalActionsCount: token.totalActionsCount,
    color: isPartnerType ? stringToColor(token.pointer + index) : '',
    ...labels.reduce((acc, label) => ({ ...acc, [label.title]: token.actions[label.id]?.value || 0 }), {}),
  }));

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === OrderType.ASC;
    setOrder(isAsc ? OrderType.DESC : OrderType.ASC);
    setOrderBy(property);
  };

  const sortedRows = rows.sort(getComparator(order, orderBy as keyof Data));

  const headers: FileHeader[] = [
    { label: columnName, key: 'name' },
    { label: 'Total Actions', key: 'totalActionsCount' },
    ...labels.map((label) => ({ label: label.title, key: label.title })),
  ];

  if (isLoading) return <div>Loading...</div>;

  return (
    <Box sx={{ width: '100%' }}>
      <Box display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
        {sortedRows.length > 0 && (
          <ExportButton
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            data={sortedRows.map(({ color, ...rest }) => rest)}
            headers={headers}
            filename={getFilename(isPartnerType ? 'partners_analytics' : 'users_analytics')}
          />
        )}
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="analytics table">
          <TableHead>
            <TableRow>
              <TableCell />
              {headers.map((header) => (
                <TableCell key={header.key} sortDirection={orderBy === header.key ? order : false}>
                  <TableSortLabel
                    active={orderBy === header.key}
                    direction={orderBy === header.key ? order : OrderType.ASC}
                    onClick={() => handleRequestSort(header.key)}>
                    {header.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.length > 0 ? (
              sortedRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ width: '1%' }} align="right">
                    <Box sx={{ width: 16, height: 16, backgroundColor: row.color, borderRadius: '4px' }} />
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.totalActionsCount}</TableCell>
                  {labels.map((label) => (
                    <TableCell key={label.id}>{row[label.title]}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={labels.length + 3} align="center" sx={{ color: 'text.secondary' }}>
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AnalyticsTable;
