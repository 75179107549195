import { Box, Typography } from '@mui/material';
import React from 'react';

import ActionsBlock from '../components/ActionsBlock';
import ApiKeyBlock from '../components/ApiKeyBlock';
import { useUserInfo } from '../hooks/useUserInfo';

const SettingsPage: React.FC = () => {
  const { userInfo, isLoading: isUserInfoLoading, error: userInfoError } = useUserInfo();

  const errorMessages = [
    {
      condition: isUserInfoLoading,
      message: 'Loading...',
      component: <Typography>Loading...</Typography>,
    },
    {
      condition: !!userInfoError,
      message: userInfoError?.message,
      component: <div>Error: {userInfoError?.message}</div>,
    },
  ];

  const errorMessage = errorMessages.find((item) => item.condition)?.component;

  if (errorMessage) {
    return errorMessage;
  }

  const projectId = userInfo?.projects?.[0]?.id.toString() || '';

  return (
    <Box sx={{ padding: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ alignSelf: 'flex-start', marginBottom: '1rem' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          Settings
        </Typography>
      </Box>

      <Box sx={{ width: '38.75rem', marginBottom: '2rem' }}>
        <ApiKeyBlock projectId={projectId} />
      </Box>

      <Box sx={{ width: '38.75rem' }}>
        <ActionsBlock projectInfo={userInfo?.projects[0]} />
      </Box>
    </Box>
  );
};

export default SettingsPage;
