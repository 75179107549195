import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CreateTokenListItemDto, TokenListItemDto, UpdateTokenListItemDto } from '../api/api';
import EditableTable from '../components/EditableTable';
import ListInfo from '../components/ListInfo';
import ListModal from '../components/ListModal';
import PartnerModal from '../components/Partner/PartnerModal';
import PartnerList from '../components/PartnerList';
import { usePartnersList } from '../hooks/usePartnersList';
import { ListModalType, PartnerModalType } from '../types/modalType';

const ListPage: React.FC = () => {
  const { listId } = useParams<{ listId: string }>();
  const { partnerList, isLoading, error, addToken, updateToken } = usePartnersList(listId);
  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState(false);
  const [isListModalOpen, setIsListModalOpen] = useState(false);
  const [modalType, setModalType] = useState<PartnerModalType>(PartnerModalType.ADD_PARTNER);
  const partnerRef = useRef<TokenListItemDto | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modifiedTokens, setModifiedTokens] = useState<Record<number, UpdateTokenListItemDto>>({});
  const [hasValidationError, setHasValidationError] = useState(false);

  const handleOpenPartnerModal = (type: PartnerModalType) => {
    setModalType(type);
    setIsPartnerModalOpen(true);
  };

  const handleListModalClick = () => {
    setIsListModalOpen(!isListModalOpen);
  };

  const handleSubmit = async (formValues: CreateTokenListItemDto | UpdateTokenListItemDto) => {
    const actions: Record<PartnerModalType, (data: any) => Promise<void>> = {
      [PartnerModalType.ADD_PARTNER]: async (data: CreateTokenListItemDto) => {
        await addToken(data);
      },
      [PartnerModalType.EDIT_PARTNER]: async (data: UpdateTokenListItemDto) => {
        await updateToken(data, String(partnerRef.current?.id));
      },
    };

    await actions[modalType](formValues);
    setIsPartnerModalOpen(false);
  };

  const handleRowClick = (partner: TokenListItemDto) => {
    partnerRef.current = partner;
    handleOpenPartnerModal(PartnerModalType.EDIT_PARTNER);
  };

  const toggleEditMode = () => {
    setIsEditing((prevState) => !prevState);
  };

  const handleTokenChange = (id: number, updatedToken: Partial<UpdateTokenListItemDto>) => {
    setModifiedTokens((prev) => ({
      ...prev,
      [id]: { ...prev[id], ...updatedToken },
    }));
  };

  const handleValidationError = (hasError: boolean) => {
    setHasValidationError(hasError);
  };

  const handleSave = async () => {
    setIsSaveModalOpen(false);
    await Promise.all(Object.entries(modifiedTokens).map(([id, updatedToken]) => updateToken(updatedToken, id)));
    setModifiedTokens({});
    setIsEditing(false);
  };

  const handleCancel = () => {
    setModifiedTokens({});
    setIsEditing(false);
  };

  if (!listId || isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const modifiedTokenCount = Object.keys(modifiedTokens).length;

  return (
    <div>
      <ListInfo name={partnerList?.name} note={partnerList?.note} handleSettingsClick={handleListModalClick} />
      {partnerList && (
        <Grid container justifyContent="flex-end" spacing={2} sx={{ mb: 2 }}>
          {!isEditing && (
            <Grid item>
              <Button onClick={toggleEditMode} variant="outlined">
                EDIT
              </Button>
            </Grid>
          )}

          {isEditing && (
            <>
              <Grid item>
                <Button variant="outlined" color="error" onClick={handleCancel}>
                  CANCEL
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => setIsSaveModalOpen(true)}
                  disabled={modifiedTokenCount === 0 || hasValidationError}>
                  SAVE
                </Button>
              </Grid>
            </>
          )}

          <Grid item>
            <Button onClick={() => handleOpenPartnerModal(PartnerModalType.ADD_PARTNER)} variant="contained">
              ADD PARTNER
            </Button>
          </Grid>
        </Grid>
      )}

      {partnerList ? (
        isEditing ? (
          <EditableTable
            list={partnerList}
            onTokenChange={handleTokenChange}
            onValidationError={handleValidationError}
          />
        ) : (
          <PartnerList list={partnerList} handleRowClick={handleRowClick} />
        )
      ) : (
        <div>No data available</div>
      )}

      <PartnerModal
        type={modalType}
        open={isPartnerModalOpen}
        onClose={() => setIsPartnerModalOpen(false)}
        listId={partnerList?.id ?? 0}
        onSubmit={handleSubmit}
        partnerData={modalType === PartnerModalType.EDIT_PARTNER && partnerRef.current ? partnerRef.current : undefined}
        customFieldsLabels={partnerList?.customFieldsLabels}
      />

      <ListModal
        open={isListModalOpen}
        onClose={handleListModalClick}
        tokenList={partnerList}
        type={ListModalType.EDIT_LIST}
      />
      <Modal open={isSaveModalOpen} onClose={() => setIsSaveModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxWidth: 400,
            textAlign: 'center',
          }}>
          <Typography variant="h6" component="h2" mb={2}>
            Confirm Changes
          </Typography>
          <Typography variant="body1" mb={4}>
            Do you want to update {Object.keys(modifiedTokens).length} partners?
          </Typography>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Confirm
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="error" onClick={() => setIsSaveModalOpen(false)}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ListPage;
