export enum PartnerModalType {
  ADD_PARTNER = 'ADD',
  EDIT_PARTNER = 'EDIT',
}

export enum AuthModalType {
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  RECOVERY = 'RECOVERY',
}

export enum ListModalType {
  ADD_LIST = 'ADD',
  EDIT_LIST = 'EDIT',
}
