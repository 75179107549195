import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import ListCard from '../components/ListCard';
import ListModal from '../components/ListModal';
import { BASE_PATH } from '../constants/routes';
import { useUserInfo } from '../hooks/useUserInfo';
import { ListModalType } from '../types/modalType';

const ProjectPage: React.FC = () => {
  const { userInfo, isLoading } = useUserInfo();
  const [isListModalOpen, setIsListModalOpen] = useState(false);

  const projectId = userInfo?.projects[0]?.id;
  const projectName = userInfo?.projects[0]?.name;
  const projectDescription = userInfo?.projects[0]?.note;

  const handleListModalClick = () => {
    setIsListModalOpen(!isListModalOpen);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Box
        sx={{
          mt: 4,
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '3fr 1fr' },
          gap: 2,
          alignItems: 'flex-start',
        }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {projectName}
          </Typography>

          <Typography variant="body1" color="textSecondary" sx={{ wordWrap: 'break-word' }}>
            {projectDescription}
          </Typography>
        </Box>

        <Button
          onClick={() => setIsListModalOpen(true)}
          variant="outlined"
          sx={{
            color: '#000',
            mb: 4,
            textTransform: 'none',
            borderColor: '#000',
            borderRadius: '8px',
            padding: '6px 16px',
            boxShadow: '0 4px 0 #000',
            '&:hover': {
              backgroundColor: '#f5f5f5',
              borderColor: '#000',
              boxShadow: '0 2px 0 #000',
            },
            justifySelf: { xs: 'center', md: 'end' },
            minWidth: '150px',
          }}>
          Create new list
        </Button>
      </Box>

      <Grid container spacing={2}>
        {userInfo?.projects[0].tokenLists.map((list) => (
          <Grid item xs={12} sm={6} md={3} key={list.id}>
            <NavLink
              to={BASE_PATH.LIST.replace(':projectId', String(projectId)).replace(':listId', String(list.id))}
              style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListCard title={list.name} description={list.note} />
            </NavLink>
          </Grid>
        ))}
      </Grid>

      <ListModal
        open={isListModalOpen}
        onClose={handleListModalClick}
        tokenList={undefined}
        type={ListModalType.ADD_LIST}
      />
    </div>
  );
};

export default ProjectPage;
