import React from 'react';

import { TokenListDto } from '../api/api';
import { ListModalType } from '../types/modalType';
import DetailsBlock from './DetailsBlock';
import Modal from './Modal';

interface ListModalProps {
  type: ListModalType;
  onClose: () => void;
  open: boolean;
  tokenList: TokenListDto | undefined;
}

const ListModal: React.FC<ListModalProps> = ({ onClose, open, tokenList, type }) => {
  const listModalLabels = {
    [ListModalType.EDIT_LIST]: 'List settings',
    [ListModalType.ADD_LIST]: 'New list',
  };

  const label = listModalLabels[type] || '';

  const content = <DetailsBlock tokenList={tokenList} onClose={onClose} type={type} />;

  return <Modal open={open} title={label} showCloseButton={true} modalContent={content} onClose={onClose} />;
};

export default ListModal;
