import { CreateCustomFieldValueDto } from '../api/api';

// export const toCamelCase = (label: string): string => {
//   return label
//     .split(' ')
//     .map((word, index) =>
//       index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
//     )
//     .join('');
// };

export const formatLabelToLowerCase = (label: string): string => {
  return label.replace(/\s+/g, '').toLowerCase();
};

export const formRequestHeader = () => {
  const token = localStorage.getItem('jwt');
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
};

export const convertCustomFieldValuesToObject = (
  data: CreateCustomFieldValueDto | Record<string, string> | undefined
): Record<string, string> => {
  if (!data || !Array.isArray(data)) {
    return (data as Record<string, string>) || {};
  }

  return data.reduce(
    (acc, item) => {
      const [key, value] = Object.entries(item)[0];
      acc[key] = value;
      return acc;
    },
    {} as Record<string, string>
  );
};

/**
 * This function sanitizes custom field values by converting them into a consistent format.
 * It accepts an object or array of key-value pairs where the values can be of type string, number, or boolean.
 * The function returns a new object where all values are converted to strings.
 *
 * @param values - An object or array of custom field values, where the values can be string, number, or boolean.
 * @returns A Record<string, string> where all values are converted to strings. If input is undefined, it returns an empty object.
 */
export const sanitizeCustomFieldValues = (
  values: { [key: string]: string | number | boolean } | undefined
): Record<string, string> => {
  if (!values) return {};

  let sanitized: Record<string, string> = {};

  if (Array.isArray(values)) {
    sanitized = values.reduce(
      (acc, item) => {
        const [key, value] = Object.entries(item)[0];
        acc[key] = String(value);
        return acc;
      },
      {} as Record<string, string>
    );
  } else {
    Object.entries(values).forEach(([key, value]) => {
      sanitized[key] = String(value);
    });
  }

  return sanitized;
};

const stringToRandomHue = (base: number, variation: number): number => {
  return (base + variation) % 360;
};

const stringToHSLWithVariation = (str: string, variation: number): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = stringToRandomHue(Math.abs(hash) % 360, variation);
  const saturation = 60 + Math.abs(hash % 30);
  const lightness = 55 + Math.abs(hash % 15);
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const generateRadialGradientFromText = (text: string): string => {
  const color1 = stringToHSLWithVariation(text + '1', 0);
  const color2 = stringToHSLWithVariation(text + '2', 60);
  const color3 = stringToHSLWithVariation(text + '3', 120);
  const color4 = stringToHSLWithVariation(text + '4', 180);
  const color5 = stringToHSLWithVariation(text + '5', 240);

  return `
      radial-gradient(circle at 20% 30%, ${color1}, transparent),
      radial-gradient(circle at 80% 20%, ${color2}, transparent),
      radial-gradient(circle at 50% 70%, ${color3}, transparent),
      radial-gradient(circle at 90% 80%, ${color4}, transparent),
      radial-gradient(circle at 30% 90%, ${color5}, transparent)
    `;
};

export const stringToColor = (string: string): string => {
  return stringToHSLWithVariation(string, 0);
};

export const getFilename = (prefix: string): string => {
  const dateTime = new Date()
    .toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(/[/,:\s]/g, '_');

  return `${prefix}_${dateTime}.csv`;
};
