import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';
import React from 'react';
import { CSVLink } from 'react-csv';

import { FileHeader } from '../types/exportType';

interface ExportButtonProps {
  data: object[];
  filename?: string;
  label?: string;
  headers?: FileHeader[];
}

const ExportButton: React.FC<ExportButtonProps> = ({ data, filename = 'export.csv', label = 'Export', headers }) => {
  return (
    <CSVLink data={data} filename={filename} style={{ textDecoration: 'none' }} headers={headers}>
      <Button variant="contained" color="primary" startIcon={<DownloadIcon />}>
        {label}
      </Button>
    </CSVLink>
  );
};

export default ExportButton;
