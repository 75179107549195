import { Paper, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { NetworkIcon } from '@web3icons/react';
import React from 'react';

import { CreateCustomFieldValueDto, NetworkInfoDto, TokenListDto, TokenListItemDto } from '../api/api';
import { useNetworksList } from '../hooks/useNetworksList';
import { formatLabelToLowerCase, sanitizeCustomFieldValues } from '../utils/helpers';

interface Column {
  id: string;
  label: string;
  format?: (value: any) => string;
  accessor?: (item: TokenListItemDto) => any;
  sx?: SxProps;
}

const getNetworkIcon = (networkId: number, networksList: NetworkInfoDto[] | undefined) => {
  const network = networksList?.find((network) => network.id === networkId);

  try {
    if (network) {
      return <NetworkIcon network={network.name} variant="branded" />;
    }
  } catch {
    console.debug(`Network icon for ${network?.name} not found, using Ethereum mono icon`);
  }

  return <NetworkIcon network="Ethereum" variant="mono" />;
};

export interface PartnerListProps {
  list: TokenListDto;
  handleRowClick: (row: TokenListItemDto) => void;
}
const PartnerList: React.FC<PartnerListProps> = ({ list, handleRowClick }) => {
  const { networksList } = useNetworksList();
  const columns: Column[] = [
    {
      id: 'chainIcon',
      label: '',
      accessor: (item: TokenListItemDto) => getNetworkIcon(item.token.networkId, networksList),
      sx: { width: '1%', paddingBottom: '0.65rem' },
    },
    { id: 'name', label: 'Partner', accessor: (item: TokenListItemDto) => item.token.name },
    { id: 'overallUsage', label: 'Overall Usage', accessor: (item: TokenListItemDto) => item.overallUsage },
    { id: 'uniqueUsers', label: 'Unique Users', accessor: (item: TokenListItemDto) => item.uniqueUsers },
    ...list.customFieldsLabels.map((label) => {
      const key = formatLabelToLowerCase(label.name);
      return {
        id: key,
        label: label.name,
        accessor: (item: TokenListItemDto) => {
          const customField = sanitizeCustomFieldValues(item.customFieldsValues);
          return (
            customField[formatLabelToLowerCase(label.name) as keyof CreateCustomFieldValueDto] || label.defaultValue
          );
        },
      };
    }),
    { id: 'note', label: 'Note', accessor: (item: TokenListItemDto) => item.note },
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {list.items.map((item) => (
            <TableRow
              hover
              key={item.id}
              onClick={() => handleRowClick(item)}
              sx={{ backgroundColor: item.isActive ? 'inherit' : '#f0f0f0' }}>
              {columns.map((column) => {
                const value = column.accessor ? column.accessor(item) : (item as any)[column.id];
                return (
                  <TableCell sx={column.sx} key={column.id}>
                    {column.format ? column.format(value) : value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PartnerList;
