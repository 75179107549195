import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';

import { AnalyticsTabs } from '../types/analityticsTabs';

interface TabsComponentProps {
  titles: AnalyticsTabs[];
  selectedTab: AnalyticsTabs;
  onChange: (newTab: AnalyticsTabs) => void;
}

const TabsComponent: React.FC<TabsComponentProps> = ({ titles, selectedTab, onChange }) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChange(titles[newValue]);
  };

  return (
    <Box sx={{ borderBottom: '0.0625rem solid black ', width: 'fit-content', mb: 2 }}>
      <Tabs
        value={titles.indexOf(selectedTab)}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            background: 'linear-gradient(to right, #9b5de5, #f15f79, #f5c542)',
          },
        }}
        sx={{
          '& .MuiTabs-indicator': {
            transition: 'none',
          },
        }}>
        {titles.map((title, index) => (
          <Tab
            key={index}
            label={title}
            sx={{
              textTransform: 'none',
              fontWeight: 'normal',
              fontSize: '0.875rem',
              color: 'black',
              '&.Mui-selected': {
                color: 'black',
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabsComponent;
