import useSWR from 'swr';

import { AbstractStatisticsData } from '../types/analyticsType';
import { getTokensAnalytics, getUsersAnalytics } from '../utils/api';

export const useAnalytics = (projectId: string | undefined) => {
  const fetchUsersAnalytics = async (): Promise<AbstractStatisticsData | undefined> => {
    if (projectId) {
      const response = await getUsersAnalytics(projectId);
      if (response) return response.data;
    }
  };

  const fetchTokensAnalytics = async (): Promise<AbstractStatisticsData | undefined> => {
    if (projectId) {
      const response = await getTokensAnalytics(projectId);
      if (response) return response.data;
    }
  };

  const { data: usersAnalytics, error: usersError } = useSWR(
    projectId ? `users-analytics-${projectId}` : null,
    fetchUsersAnalytics
  );

  const { data: tokensAnalytics, error: tokensError } = useSWR(
    projectId ? `tokens-analytics-${projectId}` : null,
    fetchTokensAnalytics
  );

  return {
    usersAnalytics,
    tokensAnalytics,
    isLoading: !usersError && !usersAnalytics && !tokensError && !tokensAnalytics,
    error: usersError || tokensError,
  };
};
