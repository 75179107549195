import useSWR from 'swr';

import { ActionDto, ActionsListResponseDto, CreateActionDto, ResponseStatusDto, UpdateActionDto } from '../api/api';
import { createAction, getActionsList, updateAction } from '../utils/api';
import { useUserInfo } from './useUserInfo';

export const useActionsManagement = (projectId: string) => {
  const { isLoading: isUserInfoLoading, error: userInfoError } = useUserInfo();

  const {
    data: actionsListResponse,
    error: actionsListError,
    mutate: mutateActionsList,
  } = useSWR<ActionsListResponseDto | undefined>(
    projectId && !isUserInfoLoading && !userInfoError ? `actionsList-${projectId}` : null,
    () => getActionsList(projectId)
  );

  const actionsList: ActionDto[] | undefined = actionsListResponse?.data;

  const createNewAction = async (actionData: CreateActionDto): Promise<ResponseStatusDto | undefined> => {
    try {
      const response = await createAction(actionData, projectId);
      mutateActionsList();
      return response;
    } catch (error) {
      console.error('Error creating new action:', error);
      throw error;
    }
  };

  const updateExistingAction = async (
    actionId: string,
    updateData: UpdateActionDto
  ): Promise<ResponseStatusDto | undefined> => {
    try {
      const response = await updateAction(actionId, updateData);
      mutateActionsList();
      return response;
    } catch (error) {
      console.error('Error updating action:', error);
      throw error;
    }
  };

  return {
    actionsList,
    isLoading: isUserInfoLoading || (!actionsListError && !actionsList),
    error: userInfoError || actionsListError,
    createNewAction,
    updateExistingAction,
  };
};
